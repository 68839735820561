@import '../modules/_vars.scss';

.portfolio-main {
  color: $navy;

  .jumbotron {
    background: $navy;
    padding: 2rem 2rem;

    .display-4 {
      text-align: center;
      font-family: $Brown-Light;
      color: white;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }

  .projects {
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;

    .desktop {
      @media #{$mq-xs, $mq-sm} {
        display: none;
      }

      @media #{$mq-md, $mq-lg, $mq-xlg} {
        display: inline-block;
      }
    }

    .mobile {
      margin-bottom: 80px;
      margin-top: 20px;

      @media #{$mq-xs, $mq-sm} {
        display: inline-block;
      }

      @media #{$mq-md, $mq-lg, $mq-xlg} {
        display: none;
      }
    }

    .project {
      padding: 0;

      img {
        width: 100%;
      }

      .project-link {
        color: $navy;

        h6 {
          font-family: $Brown-Light;
          font-size: 18px;
          text-transform: uppercase;
        }
      }

      h3 {
        font-family: $Brown-Regular;
        font-size: 25px;
        text-transform: uppercase;
      }
    }
  }

  .comingsoon {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 60px;

    .project {
      margin-top: 20px;
      margin-bottom: 20px;
      text-transform: uppercase;
    }
  }
}
