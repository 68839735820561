@font-face {
  font-family: 'Brown-Light';
  src: url('../fonts/Brown-Light.eot');
  src: url('../fonts/Brown-Light.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Brown-Light.woff') format('woff'),
       url('../fonts/Brown-Light.ttf') format('truetype'),
       url('../fonts/Brown-Light.svg#font-name') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Brown-Regular';
  src: url('../fonts/Brown-Regular.eot');
  src: url('../fonts/Brown-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brown-Regular.woff') format('woff'),
  url('../fonts/Brown-Regular.ttf') format('truetype'),
  url('../fonts/Brown-Regular.svg#font-name') format('svg');
  font-weight: normal;
  font-style: normal;
}
