//----------------------------------*\
// SCSS MIXINS
//----------------------------------*/

@import 'mixins/_position.scss';

//----------------------------------*\
// CONFIG MODULES
//----------------------------------*/

// Config modules
@import 'modules/_vars.scss';
@import 'modules/_clearfix.scss';
@import 'modules/_defaults.scss';
@import 'modules/_normalize.scss';
//----------------------------------*\
// PARTIALS
//----------------------------------*/

@import 'partials/_header.scss';
@import 'partials/_nav.scss';
@import 'partials/_sidebar.scss';
@import 'partials/_main.scss';
@import 'partials/_portfolio.scss';
@import 'partials/_press.scss';
@import 'partials/_progress.scss';
@import 'partials/_project.scss';
@import 'partials/_about.scss';
@import 'partials/_footer.scss';

//----------------------------------*\
// OBJECTS & MODULES
//----------------------------------*/

@import 'modules/_buttons.scss';
@import 'modules/_inputs.scss';
@import 'modules/_lists.scss';
@import 'modules/_typography.scss';
@import 'modules/_misc.scss';
@import 'modules/_app.scss';

// Enhancement modules
@import 'modules/_breakpoints.scss';
@import 'modules/_print.scss';

//----------------------------------*\
// VENDOR
//----------------------------------*/

@import 'vendor/_external.scss';

body {
  color: $navy;
  overflow: scroll;
}

html {
  overflow: scroll;
}

.bg-light {
  background-color: white !important;
  border-bottom: 1px solid $navy;
}
