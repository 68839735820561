@import '../modules/_vars.scss';

.progress-main {

  .jumbotron {
    background: $navy;
    margin-bottom: 0;
    padding: 2rem 2rem;

    .display-4 {
      text-align: center;
      font-family: $Brown-Light;
      color: white;
      margin-bottom: 0;
      text-transform: uppercase;

      @media #{$mq-xs, $mq-sm} {
        font-size: 25px;
      }
    }
  }

  .mobile {
    display: none;
    margin: 0 auto;
    width: 100%;

    @media #{$mq-xs, $mq-sm} {
      display: inline-block;
    }

  }

  .desktop {
    @media #{$mq-xs, $mq-sm} {
      display: none;
    }
  }

}
