@import '../modules/_typography.scss';

// Colors
$base : #F7F6F0;
$darken : darken(#F7F6F0 , 30%);
$back: darken(#F7F6F0, 40%);
$navy: #323846;

// Font Family
$Brown-Regular: Brown-Regular;
$Brown-Light: Brown-Light;


// Media Queries
$mq-xs: "(max-width: 767px)";
$mq-sm: "(min-width: 768px) and (max-width: 991px)";
$mq-md: "(min-width: 992px) and (max-width: 1199px)";
$mq-lg: "(min-width: 1200px) and (max-width: 1599px)";
$mq-xlg: "(min-width: 1600px)";
