@import '../modules/_vars.scss';

.press-main {

  .container {
    max-width: 500px;
    margin-bottom: 20px;

    .card-title {
      font-family: $Brown-Regular;
      color: $navy;
    }

    .cart-text {
      font-family: $Brown-Light;
      color: $navy;
    }
  }

  .jumbotron {
    background: $navy;
    padding: 2rem 2rem;

    .display-4 {
      text-align: center;
      font-family: $Brown-Light;
      color: white;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }

}



