@import '../modules/_vars.scss';

.about-main {
  margin-bottom: 50px;
  color: $navy;

  .jumbotron {
    background: $navy;
    padding: 2rem 2rem;

    .display-4 {
      text-align: center;
      font-family: $Brown-Light;
      color: white;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }

  .image {

    @media #{$mq-xs, $mq-sm} {
      padding-left: 40px;
      padding-right: 40px;
    }

    img {
      width: 100%;
      border-radius: 2px;
    }
  }

  .detail {
    font-family: $Brown-Light;

    @media #{$mq-xs, $mq-sm} {
      margin-top: 50px;
    }

    h4 {
      font-size: 18px;
      margin-left: 30px;
      margin-right: 30px;
      margin-bottom: 30px;
      color: $navy;
    }

    .contact-info {
      text-align: center;
      margin-top: 50px;

      h4 {
        font-size: 16px;
        margin: 0;
        color: $navy;
      }
    }

    .credits {
      text-align: center;
      margin-top: 25px;
      color: $navy;
    }
  }

}
