@import '../modules/_vars.scss';

.project-main {
  color: $navy;

  .jumbotron {
    background: $navy;
    padding: 2rem 2rem;

    .display-4 {
      text-align: center;
      font-family: $Brown-Light;
      color: white;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }

  .credits {
    color: $navy;
    font-family: $Brown-Regular;
    text-align: center;
  }

  .projects {
    text-align: center;
    max-width: 700px;
    margin: 0 auto;

    .project {
      //padding: 0;
      padding: 5px;

      .sized {
        height: 446px;
      }

      .height {
        height: 110px;
      }


      img {
        width: 100%;
      }

    }
  }

}
