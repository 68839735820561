@import '../modules/_vars.scss';

nav {
  @media #{$mq-xs, $mq-sm} {
    text-align: center;
  }



  .navbar-nav {
    margin: 0 auto;
    font-size: 16px;

    .navbar-brand {
      margin-right: 0;
      display: none;

      @media #{$mq-md, $mq-lg, $mq-xlg} {
        display: inline-block;
      }

      img {
        width: 150px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    .nav-item {
      font-family: $Brown-Regular;
      text-transform: uppercase;
      margin-left: 10px;
      margin-right: 10px;
      color: $navy;

      @media #{$mq-xs, $mq-sm} {
        margin: 0 auto;
      }
    }
  }

}

.mobile-logo-link {
  @media #{$mq-xs, $mq-sm} {
    width: 100%;
    margin-bottom: 20px;
  }

  .mobile-logo {
    display: none;
    width: 100px;

    @media #{$mq-xs, $mq-sm} {
      display: inline-block;
    }

  }
}
